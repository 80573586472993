import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';

import './styles/iframe.css';

import React from 'react';
import {RouteComponentProps} from '@reach/router';

const App = React.lazy(() => import('./app'));

type EmbeddedCheckoutProps = RouteComponentProps & {};
export const EmbeddedCheckout: React.FC<EmbeddedCheckoutProps> = () => (
  <>
    <App />
  </>
);

import {css} from '@emotion/react/macro';
import {cx} from '@emotion/css';
import {sum, pick, omit} from 'lodash';
import React from 'react';
import {Field, FieldRenderProps} from 'react-final-form';
import {Form, StrictFormFieldProps} from 'semantic-ui-react';
import {useFormState} from './state-context';
import {SubmitError} from './submit-error';
import {Media} from '../styles/breakpoints';
import {RequireOnlyOne} from '../types';
import {FieldConfig} from './schema-utils';

export type FieldConfigProps = RequireOnlyOne<
  {
    fieldName?: string;
    fieldConfig?: FieldConfig;
  },
  'fieldName' | 'fieldConfig'
>;

export type FieldPropsStrict = FieldConfigProps & {
  fieldLabel?: string;
  fieldClassName?: string;
  fieldHint?: React.ReactNode;
  fieldRequired?: boolean;
  width?: StrictFormFieldProps['width'];

  renderCss?: boolean;
};

type FieldProps<T> = T & FieldPropsStrict;

type RenderComponentProps<T> = T & FieldRenderProps<any, HTMLElement>;

type RenderComponent<T> = {
  renderComponent: (renderComponentProps: RenderComponentProps<T>) => React.ReactNode;
};

const FieldPropKeys = [
  'fieldConfig',
  'fieldName',
  'fieldLabel',
  'fieldClassName',
  'fieldHint',
  'fieldRequired',
  'width',
  'inputProps',
  'renderCss',
] as const;

export function FieldFactory<T>({renderComponent}: RenderComponent<T>) {
  return (props: FieldProps<T>) => {
    const fieldProps = pick(props, FieldPropKeys);
    const finalFormFieldProps = omit(props, FieldPropKeys);

    const {fieldName, fieldLabel, fieldClassName, fieldHint, fieldRequired} = props.fieldConfig
      ? props.fieldConfig
      : fieldProps;

    const inputProps = props.fieldConfig?.inputProps ?? {};

    if (!fieldName) {
      throw new Error('fieldName is required');
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {state} = useFormState();

    return (
      <Field
        id={fieldName}
        name={fieldName}
        render={(renderComponentProps) => {
          const {input, meta} = renderComponentProps;
          return (
            <div className={cx('ui form-field', fieldClassName)}>
              <Form.Field
                width={fieldProps.width}
                error={!!meta.submitError}
                disabled={state.disabled}
                {...(fieldProps.renderCss ?? true ? {css: fieldStyles} : {})}
              >
                {fieldLabel ? (
                  fieldRequired ? (
                    <label htmlFor={input.name} className="required">{`${fieldLabel}`}</label>
                  ) : (
                    <label htmlFor={input.name}>{`${fieldLabel}`}</label>
                  )
                ) : (
                  <span className="no-label" />
                )}
                {renderComponent(renderComponentProps as RenderComponentProps<T>)}
              </Form.Field>
              {fieldHint && <div className="form-field-hint">{fieldHint}</div>}
              <SubmitError name={input.name} />
            </div>
          );
        }}
        {...(inputProps as any)}
        {...finalFormFieldProps}
      />
    );
  };
}

type FormRow = {
  proportions?: number[];
};

export const FormRow: React.FC<FormRow> = ({proportions, ...props}) => {
  const styles = getStyle(proportions);

  return <div className={cx('form-row', styles.key)} css={styles.style} {...props} />;
};

const styleCache = {};
const getStyle = (proportions: number[] = []) => {
  const key = `cols-${proportions.join('-')}`;
  if (!styleCache[key]) {
    const denominator = sum(proportions);
    styleCache[key] = css`
      ${Media('TabletMin')} {
        ${(proportions || []).map(
          (x, i) => css`
            .form-field:nth-child(${i + 1}) {
              width: ${(x / denominator) * 100}%;
              flex: none !important;
            }
          `
        )};
      }
    `;
  }

  return {key, style: styleCache[key]};
};

const fieldStyles = css`
  .required:after {
    color: red;
    content: ' *';
  }
`;

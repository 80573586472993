/// <reference types="@emotion/react/types/css-prop" />

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'typeface-roboto';
import '@reach/skip-nav/styles.css';
import '@reach/tooltip/styles.css';
import './dev-tools';
import './analytics/sentry';

import {enableES5, enableMapSet} from 'immer';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import {useHotkeys} from 'react-hotkeys-hook';
import {Redirect, RouteComponentProps, Router} from '@reach/router';
import * as Sentry from '@sentry/browser';
import AuthenticationResolver from './auth/authentication-resolver';
import {AuthProvider} from './auth/use-auth';
import {HostedCheckout} from './checkout';
import {Env} from './config/env-vars';
import {EmbeddedCheckout} from './embedded';
import {PaymentLookup} from './payments/payment-lookup';
import {AnonymousForms} from './custom-forms/anonymous-forms';
import {Receipt} from './payments/receipt';
import reportWebVitals from './reportWebVitals';
import {routes} from './routes';
import {logger} from './utils/logger';
import {Onboarding} from './onboarding';

enableES5();
enableMapSet();

const log = logger('app');

const DebugLogger = () => {
  useHotkeys('ctrl+shift+.', () => {
    localStorage.setItem('debug', 'paystar:*');
    log.info('Production logging enabled');
  });
  return null;
};

const DefaultRouteResolver: React.FC<RouteComponentProps> = () => {
  return (
    <AuthProvider>
      <AuthenticationResolver />
    </AuthProvider>
  );
};

const FallbackComponent: React.FC<FallbackProps> = ({error}) => {
  log.error(error.message);
  return null;
};

try {
  ReactDOM.render(
    <Suspense fallback={null}>
      <DebugLogger />
      <ErrorBoundary
        FallbackComponent={FallbackComponent}
        onError={(error) => {
          if (Env.isProductionBuild) {
            Sentry.withScope((scope) => {
              scope.setFingerprint([window.location.pathname]);

              Sentry.captureException(error);
            });
          }

          Sentry.showReportDialog();
        }}
      >
        <Router>
          <Receipt path={routes.receipt} />
          <PaymentLookup path={routes.paymentLookup} />
          <AnonymousForms path={routes.anonymousForms} />
          <AnonymousForms path={routes.anonymousDependentForms} />
          <HostedCheckout path={routes.checkout.base} />
          <EmbeddedCheckout path={routes.embedded.base} />
          <Onboarding path={routes.onboarding.base} />
          <Redirect noThrow from={routes.quickPayRedirect} to={routes.customer.dashboard} />
          <DefaultRouteResolver default />
        </Router>
      </ErrorBoundary>
    </Suspense>,
    document.getElementById('root')
  );
} catch (error) {
  if (Env.isProductionBuild) {
    Sentry.captureException(error);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

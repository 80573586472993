import qs from 'qs';
import {RouteComponentProps, useMatch} from '@reach/router';
import {Env} from '../config/env-vars';
import {AnyObject, KeyOf} from '../types';

type PathParams = {
  [key: string]: string | number;
};

type QueryParams = {
  [key: string]: string;
};

export type RouteParams<T> = Required<RouteComponentProps> & T;
export const appSubdirectory = Env.subdirectory || '';

export const rootRoutes = {
  callback: `${appSubdirectory}/callback`,
  portal: `${appSubdirectory}/portal`,
  customer: `${appSubdirectory}/customer`,
  checkout: `${appSubdirectory}/checkout`,
  embedded: `${appSubdirectory}/embedded`,
  onboarding: `${appSubdirectory}/onboarding/:businessUnitSlug`,
};

export const routes = {
  receipt: `${appSubdirectory}/payment/:referenceNumber/receipt`,
  paymentLookup: `${appSubdirectory}/payment/:referenceNumber/lookup`,
  anonymousForms: `${appSubdirectory}/:slug/:businessUnitId/forms/:formId/`,
  anonymousDependentForms: `${appSubdirectory}/:slug/:businessUnitId/forms/:baseFormId/:formId/`,
  checkout: {
    base: `${rootRoutes.checkout}/:slug/:signature/:expiration/*`,
    initiate: `/`,
    review: `:sessionId`,
  },
  embedded: fragmentedRoute({
    base: `${rootRoutes.embedded}/:slug/*`,
    fragments: {
      paymentSource: `payment-source`,
      session: `session/:identifier`,
      flowRoot: `flow/*`,
      manageAutopay: `flow/account/manage-autopay/*`,
      managePaperless: `flow/account/manage-paperless`,
      manageCustomerWallet: `flow/customer/manage-wallet/*`,
    },
  }),
  portal: {
    base: `${rootRoutes.portal}/*`,
    dashboard: rootRoutes.portal,
    payments: {
      root: `${rootRoutes.portal}/payments/*`,
      dashboard: `${rootRoutes.portal}/payments`,
      external: `${rootRoutes.portal}/payments/external`,
      details: `${rootRoutes.portal}/payments/:referenceNumber`,
      fragments: {
        root: '/',
        referenceNumber: ':referenceNumber',
        external: 'external',
      },
    },
    serviceFees: `${rootRoutes.portal}/service-fees`,
    termsOfService: {
      listing: `${rootRoutes.portal}/terms-of-service`,
      create: `${rootRoutes.portal}/terms-of-service/create`,
      createFromExisting: `${rootRoutes.portal}/terms-of-service/create/:id`,
      view: `${rootRoutes.portal}/terms-of-service/view/:identifier`,
    },
    funding: {
      root: `${rootRoutes.portal}/funding/*`,
      listing: `${rootRoutes.portal}/funding`,
      unfunded: `${rootRoutes.portal}/funding/unfunded`,
      fragments: {
        root: '/',
        unfunded: 'unfunded',
      },
    },
    myAccount: `${rootRoutes.portal}/my-account`,
    gateways: {
      listing: `${rootRoutes.portal}/gateways`,
      create: `${rootRoutes.portal}/gateways/create`,
      detail: `${rootRoutes.portal}/gateways/:id`,
    },
    achGateway: {
      batches: `${rootRoutes.portal}/ach-gateway/batches`,
      batchTransactions: `${rootRoutes.portal}/ach-gateway/batches/:id`,
    },
    messages: {
      listing: `${rootRoutes.portal}/messages`,
      create: `${rootRoutes.portal}/messages/create`,
      detail: `${rootRoutes.portal}/messages/:id`,
    },
    products: {
      listing: `${rootRoutes.portal}/products`,
      create: `${rootRoutes.portal}/products/create`,
      detail: `${rootRoutes.portal}/products/:id`,
    },
    merchantAccounts: {
      listing: `${rootRoutes.portal}/merchant-accounts`,
      create: `${rootRoutes.portal}/merchant-accounts/create`,
      detail: `${rootRoutes.portal}/merchant-accounts/:id`,
    },
    nachaConfigs: {
      listing: `${rootRoutes.portal}/nacha-configs`,
      create: `${rootRoutes.portal}/nacha-configs/create`,
      detail: `${rootRoutes.portal}/nacha-configs/:id`,
    },
    agencies: {
      listing: `${rootRoutes.portal}/agencies`,
      create: `${rootRoutes.portal}/agencies/create`,
      detail: `${rootRoutes.portal}/agencies/:id`,
    },
    billingSystems: {
      listing: `${rootRoutes.portal}/billing-systems`,
      create: `${rootRoutes.portal}/billing-systems/create`,
      detail: `${rootRoutes.portal}/billing-systems/:id`,
    },
    customGatewayMessages: {
      listing: `${rootRoutes.portal}/custom-gateway-messages`,
      create: `${rootRoutes.portal}/custom-gateway-messages/create`,
      detail: `${rootRoutes.portal}/custom-gateway-messages/:id`,
    },
    organization: {
      setup: `${rootRoutes.portal}/organizations/setup`,
      detail: `${rootRoutes.portal}/organization/`,
    },
    businessUnits: {
      setup: `${rootRoutes.portal}/business-units/setup`,
      root: `${rootRoutes.portal}/business-units/*`,
      detail: `${rootRoutes.portal}/business-units`,
      create: `${rootRoutes.portal}/business-units/create`,
      channels: `${rootRoutes.portal}/business-units/channels`,
      channel: `${rootRoutes.portal}/business-units/channel/:id`,
      terms: {
        listing: `${rootRoutes.portal}/business-units/terms-of-service`,
        create: `${rootRoutes.portal}/business-units/terms-of-service/create`,
        createFromExisting: `${rootRoutes.portal}/business-units/terms-of-service/create/:id`,
        view: `${rootRoutes.portal}/business-units/terms-of-service/view/:identifier`,
      },
      externalLinks: {
        listing: `${rootRoutes.portal}/business-units/external-links`,
        create: `${rootRoutes.portal}/business-units/external-links/create`,
        update: `${rootRoutes.portal}/business-units/external-links/:id`,
      },
      fragments: {
        root: '/',
        features: 'features',
        channels: 'channels',
        billingCycles: 'billing-cycles',
        accountConfig: 'account-config',
        paymentFields: 'payment-fields',
        termsOfService: 'terms-of-service',
        externalLinks: 'external-links',
      },
      billingCycleDetails: `${rootRoutes.portal}/billing-cycle/:id`,
    },
    reports: {
      root: `${rootRoutes.portal}/reports/*`,
      batches: `${rootRoutes.portal}/reports/batches`,
      batchDetails: `${rootRoutes.portal}/reports/batches/:merchantNumber`,
      transactions: `${rootRoutes.portal}/reports/transactions`,
      payments: `${rootRoutes.portal}/reports/payments`,
      adjustments: `${rootRoutes.portal}/reports/adjustments`,
      fragments: {
        root: '/',
        batchesByMid: 'batches/:merchantNumber',
        batches: 'batches',
        transactions: 'transactions',
        posReport: 'pos-report',
        adjustments: 'adjustments',
        autopay: 'autopay',
        paperlessBilling: 'paperless-billing',
        products: 'products',
        generated: 'generated',
        accountPayment: 'account-payments',
      },
    },
    accounts: {
      listing: `${rootRoutes.portal}/accounts`,
      uploads: `${rootRoutes.portal}/accounts/uploads`,
      create: `${rootRoutes.portal}/accounts/create`,
      detail: `${rootRoutes.portal}/accounts/:id`,
      dashboard: `${rootRoutes.portal}/accounts/:id/dashboard`,
    },
    customers: {
      dashboard: `${rootRoutes.portal}/customers/:id/dashboard`,
    },
    devices: {
      listing: `${rootRoutes.portal}/devices`,
      create: `${rootRoutes.portal}/devices/create`,
      detail: `${rootRoutes.portal}/devices/:id`,
    },
    bills: {
      listing: `${rootRoutes.portal}/bills`,
    },
    readings: {
      listing: `${rootRoutes.portal}/readings`,
    },
    users: {
      listing: `${rootRoutes.portal}/users`,
      create: `${rootRoutes.portal}/users/create`,
      detail: `${rootRoutes.portal}/users/:id`,
    },
    usersPage: {
      root: `${rootRoutes.portal}/users-page/*`,
      billers: `${rootRoutes.portal}/users-page`,
      fragments: {
        root: '/',
        payers: `customers`,
      },
    },
    tickets: {
      listing: `${rootRoutes.portal}/tickets`,
      create: `${rootRoutes.portal}/tickets/create`,
      detail: `${rootRoutes.portal}/tickets/:id`,
    },
    settings: {
      listing: `${rootRoutes.portal}/settings`,
      detail: `${rootRoutes.portal}/settings/:id`,
    },
    webhooks: {
      listing: `${rootRoutes.portal}/webhooks`,
    },
    jobs: {
      listing: `${rootRoutes.portal}/jobs`,
      editor: `${rootRoutes.portal}/jobs/:id`,
      templates: `${rootRoutes.portal}/jobs/templates`,
    },
    files: {
      listing: `${rootRoutes.portal}/files`,
    },
    outboundIvr: `${rootRoutes.portal}/outboundIvr`,
    signOut: `${rootRoutes.portal}/signout`,
    businessUnitClientManagementSettings: `${rootRoutes.portal}/business-unit-settings`,
    embeddedSettings: `${rootRoutes.portal}/embedded-settings`,
  },
  customer: {
    base: `${rootRoutes.customer}/:slug/*`,
    dashboard: `${rootRoutes.customer}/:slug`,
    paymentIntentFlow: `${rootRoutes.customer}/:slug/make-payment/:identifier`,
    multiAccountDashboard: `${rootRoutes.customer}/:slug/accounts`,

    accountBase: `${rootRoutes.customer}/:slug/accounts/:accountId/*`,
    account: `${rootRoutes.customer}/:slug/accounts/:accountId`,
    accountRegister: `${rootRoutes.customer}/:slug/accounts/:accountId/register`,

    wallet: `${rootRoutes.customer}/:slug/wallet`,
    form: `${rootRoutes.customer}/:slug/form/:formId`,
    dependentForm: `${rootRoutes.customer}/:slug/form/:baseFormId/:formId`,
    myAccount: `${rootRoutes.customer}/:slug/paystar-profile`,
  },
  payer: {
    base: `${rootRoutes.customer}/:slug/*`,
    dashboard: `${rootRoutes.customer}/:slug`,
    accountList: `${rootRoutes.customer}/:slug/accounts`,
    accounts: fragmentedRoute({
      base: `${rootRoutes.customer}/:slug/accounts/:accountId/*`,
      fragments: {
        root: '/',
        paymentHistory: 'payment-history',
        billHistory: 'bill-history',
        autoPay: 'auto-pay',
        paperlessBilling: 'paperless-billing',
        pay: 'pay',
        paystarProfile: 'paystar-profile',
        register: 'register',
      },
    }),
  },
  quickPayRedirect: `${appSubdirectory}/quick-pay/:slug`,
  onboarding: {
    base: `${rootRoutes.onboarding}/:token/*`,
    customerVerification: `${rootRoutes.onboarding}/:token`,
    accountVerification: `${rootRoutes.onboarding}/:token/account/:accountId`,
    paperlessBillingEnrollment: `${rootRoutes.onboarding}/:token/account/:accountId/paperless-billing`,
    autopayEnrollment: `${rootRoutes.onboarding}/:token/account/:accountId/autopay`,
    notificationsEnrollment: `${rootRoutes.onboarding}/:token/account/:accountId/notifications`,
    verifyPhone: `${rootRoutes.onboarding}/:token/account/:accountId/verify-phone`,
    multiAccountNotification: `${rootRoutes.onboarding}/:token/account/:accountId/multi-account-notification`,
    invalidLink: `${rootRoutes.onboarding}/:token/invalid`,
    completed: `${rootRoutes.onboarding}/:token/complete`,
  },
};

type FragmentedRouteConfig<TFragments extends AnyObject> = {
  base: string;
  fragments: {
    [key in keyof TFragments]: string;
  };
};

type FragmentMatchBase = {
  any: boolean;
};

function fragmentedRoute<T extends AnyObject>(config: FragmentedRouteConfig<T>) {
  type Fragments = KeyOf<T>;

  const resolveFragment = (key: Fragments) => {
    return config.base.replace('/*', `/${config.fragments[key]}`);
  };

  const matcher =
    <U extends AnyObject<Fragments>>(fragmentConfig: U) =>
    () => {
      type FragmentMatch = FragmentMatchBase & {
        [key in keyof U]: ReturnType<typeof useMatch>;
      };

      let result = {
        any: false,
      };

      Object.keys(fragmentConfig).forEach((key) => {
        const fragment = resolveFragment(fragmentConfig[key] as Fragments);
        const match = useMatch(fragment);

        result[key] = match;

        if (match) {
          result.any = true;
        }
      });

      return result as FragmentMatch;
    };
  return {
    ...config,
    resolveFragment,
    matcher,
  };
}

export const buildPath = (path: string, pathParams: PathParams, queryParams?: QueryParams) => {
  const compiledPath = Object.keys(pathParams).reduce((url, key) => {
    return url.replace(`:${key}`, `${pathParams[key]}`);
  }, path);

  const url = queryParams ? `${compiledPath}?${qs.stringify(queryParams)}` : compiledPath;
  return url.replace('//', '/');
};

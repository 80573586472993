import {FieldCustomValue} from './adapters/custom-value';
import {Button} from './button';
import {FormContainer} from './container';
import {Debug} from './debug';
import {ArrayMutators, FieldArray} from './field-arrays';
import * as Fields from './fields';
import {Form as BaseForm, FinalFormProps} from './form';
import {FormSection} from './section';
import {FormRow} from './field-factory';
import {MultiSelectCheckbox} from './multi-select-checkbox';

type Form = typeof BaseForm & {
  Container: typeof FormContainer;
  Section: typeof FormSection;
  Row: typeof FormRow;

  Button: typeof Button;
  Checkbox: typeof Fields.FieldCheckbox;
  Dropdown: typeof Fields.FieldDropdown;
  Input: typeof Fields.FieldInput;
  InputFileUrl: typeof Fields.FieldInputFileUrl;
  InputCurrency: typeof Fields.FieldInputCurrency;
  InputDecimal: typeof Fields.FieldInputDecimal;
  InputMasked: typeof Fields.FieldInputMasked;
  RadioGroup: typeof Fields.FieldRadioGroup;
  TextArea: typeof Fields.FieldTextArea;
  DatePicker: typeof Fields.FieldDatePicker;
  CustomValue: typeof FieldCustomValue;
  Debug: typeof Debug;
  FieldArray: typeof FieldArray;
  ArrayMutators: typeof ArrayMutators;
  MultiSelectCheckbox: typeof MultiSelectCheckbox;
};

export const Form = BaseForm as Form;
export type FormProps = FinalFormProps;

Form.Container = FormContainer;
Form.Section = FormSection;
Form.Row = FormRow;

Form.Button = Button;
Form.Checkbox = Fields.FieldCheckbox;
Form.Dropdown = Fields.FieldDropdown;
Form.Input = Fields.FieldInput;
Form.InputFileUrl = Fields.FieldInputFileUrl;
Form.InputCurrency = Fields.FieldInputCurrency;
Form.InputDecimal = Fields.FieldInputDecimal;
Form.InputMasked = Fields.FieldInputMasked;
Form.RadioGroup = Fields.FieldRadioGroup;
Form.TextArea = Fields.FieldTextArea;
Form.DatePicker = Fields.FieldDatePicker;
Form.CustomValue = FieldCustomValue;

Form.Debug = Debug;
Form.FieldArray = FieldArray;
Form.ArrayMutators = ArrayMutators;
Form.MultiSelectCheckbox = MultiSelectCheckbox;
